const data = {
  headers: [
    { text: "Name", value: "name", edit: true, widget: "text" },
    {
      text: "Description",
      value: "description",
      edit: true,
      widget: "textarea",
    },
    { text: "Status", value: "status" },
    { text: "Items/Results", value: "items_results" },
    { text: "Ready", value: "ready" },
  ],
  items: [
    {
      name: "Brightdog",
      description:
        "Morbi sem mauris, laoreet ut, rhoncus aliquet, pulvinar sed, nisl.",
      status: "Trial",
      items_results: 545,
      ready: 7,
    },
    {
      name: "Youspan",
      description: "Cras non velit nec nisi vulputate nonummy.",
      status: "Inactive",
      items_results: 664,
      ready: 65,
    },
    {
      name: "Devshare",
      description:
        "Vivamus metus arcu, adipiscing molestie, hendrerit at, vulputate vitae, nisl. Aenean lectus.",
      status: "Trial",
      items_results: 664,
      ready: 11,
    },
    {
      name: "Photofeed",
      description: "Maecenas pulvinar lobortis est.",
      status: "Inactive",
      items_results: 647,
      ready: 13,
    },
    {
      name: "Myworks",
      description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
      status: "Trial",
      items_results: 165,
      ready: 30,
    },
    {
      name: "Skibox",
      description: "Mauris sit amet eros.",
      status: "Trial",
      items_results: 677,
      ready: 13,
    },
    {
      name: "Yacero",
      description:
        "Fusce lacus purus, aliquet at, feugiat non, pretium quis, lectus. Suspendisse potenti.",
      status: "Active",
      items_results: 149,
      ready: 53,
    },
    {
      name: "JumpXS",
      description:
        "Morbi sem mauris, laoreet ut, rhoncus aliquet, pulvinar sed, nisl. Nunc rhoncus dui vel sem.",
      status: "Inactive",
      items_results: 274,
      ready: 45,
    },
    {
      name: "Tagopia",
      description: "Aenean lectus.",
      status: "Trial",
      items_results: 767,
      ready: 12,
    },
    {
      name: "Jaxworks",
      description:
        "Vivamus vel nulla eget eros elementum pellentesque. Quisque porta volutpat erat.",
      status: "Trial",
      items_results: 133,
      ready: 56,
    },
    {
      name: "Centimia",
      description: "Nunc nisl.",
      status: "Trial",
      items_results: 708,
      ready: 41,
    },
    {
      name: "Trudeo",
      description:
        "Morbi vestibulum, velit id pretium iaculis, diam erat fermentum justo, nec condimentum neque sapien placerat ante.",
      status: "Active",
      items_results: 248,
      ready: 52,
    },
    {
      name: "Brainlounge",
      description:
        "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla dapibus dolor vel est.",
      status: "Active",
      items_results: 613,
      ready: 68,
    },
    {
      name: "Wikizz",
      description:
        "Cras mi pede, malesuada in, imperdiet et, commodo vulputate, justo. In blandit ultrices enim.",
      status: "Active",
      items_results: 675,
      ready: 50,
    },
    {
      name: "Twitterworks",
      description: "In sagittis dui vel nisl.",
      status: "Active",
      items_results: 326,
      ready: 63,
    },
    {
      name: "Tanoodle",
      description:
        "Quisque arcu libero, rutrum ac, lobortis vel, dapibus at, diam.",
      status: "Active",
      items_results: 385,
      ready: 68,
    },
    {
      name: "Gabtune",
      description:
        "Quisque erat eros, viverra eget, congue eget, semper rutrum, nulla.",
      status: "Trial",
      items_results: 568,
      ready: 32,
    },
    {
      name: "Yodoo",
      description: "Morbi a ipsum. Integer a nibh.",
      status: "Trial",
      items_results: 272,
      ready: 44,
    },
    {
      name: "Devpoint",
      description: "Proin at turpis a pede posuere nonummy. Integer non velit.",
      status: "Active",
      items_results: 981,
      ready: 73,
    },
    {
      name: "Skyvu",
      description: "Curabitur at ipsum ac tellus semper interdum.",
      status: "Inactive",
      items_results: 463,
      ready: 27,
    },
  ],
};

module.exports = data;
