<template>
  <div class="home">
    <v-row align="center" justify="center">
      <v-row>
        <customer-list-bar
          :clients="is_active_customer"
          :trials="is_trial_customer"
          :isDisabled="is_disabled_customer"
        ></customer-list-bar>
      </v-row>
      <div class="mt-4" v-if="renderComponent" id="newCustomerList">
        <v-row
          ><v-col cols="3">
            <v-select
              :items="[
                { name: 'Enabled', enabled: true },
                { name: 'Disabled', enabled: false },
                { name: 'All', enabled: null },
              ]"
              item-value="enabled"
              item-text="name"
              label="Status"
              v-model="customerStatus"
              @input="updateCustomerStatus(customerStatus)"
            ></v-select
          ></v-col>
          <v-col cols="3">
            <v-select
              :items="[
                { name: 'Yes', is_trial: true },
                { name: 'No', is_trial: false },
                { name: 'All', is_trial: null },
              ]"
              item-value="is_trial"
              item-text="name"
              label="Trial"
              v-model="customerTrial"
              @input="updateCustomerTrial(customerTrial)"
            ></v-select
          ></v-col>
          <v-col cols="3">
            <v-select
              :items="users"
              item-value="id"
              item-text="full_name"
              label="Analyst"
              v-model="customerByUser"
              @input="updateCustomersByUser(customerByUser)"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="search"
              placeholder="Filter Results"
            ></v-text-field> </v-col
        ></v-row>

        <v-data-table
          :headers="headers"
          :items="customers"
          :items-per-page="-1"
          disable-sort
          :search="search"
          hide-default-footer
        >
          <template v-slot:item.status="{ item }"
            ><td>
              <v-icon
                color="#ff5252 "
                medium
                class="mr-2"
                v-if="item.enabled & item.is_trial"
                >mdi-check-circle
              </v-icon>
              <v-icon
                color="#58ABE7"
                medium
                class="mr-2 rowIconTrial"
                v-if="item.enabled & !item.is_trial"
                >mdi-check-circle
              </v-icon>
              <v-icon
                color="#112748"
                medium
                class="mr-2"
                style="
                  color: #112748 !important;
                  caret-color: #112748 !important;
                "
                v-if="!item.enabled"
                >mdi-checkbox-blank-circle
              </v-icon>
            </td></template
          >
          <template v-slot:item.manage="{ item }">
            <v-row>
              <v-col cols="1" class="mr-2">
                <v-icon
                  class="rowIcon"
                  @click="customerReports(item)"
                  v-if="item.status !== 'Disabled'"
                >
                  mdi-table-large
                </v-icon>
                <v-icon
                  class="rowIcon"
                  disabled
                  v-if="item.status == 'Disabled'"
                >
                  mdi-table-large-remove
                </v-icon>
              </v-col>
              <v-col cols="1" class="mr-2">
                <v-icon class="rowIcon" @click="customerItems(item)">
                  mdi-format-list-bulleted-type
                </v-icon>
              </v-col>
              <v-col cols="1" class="ml-0">
                <v-icon class="rowIcon" @click="customerProfile(item)">
                  mdi-account-circle
                </v-icon>
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <v-snackbar
          v-model="showClipboard"
          color="#58abe7"
          centered
          :timeout="3000"
          elevation="24"
        >
          {{ showMsg }}
        </v-snackbar>
      </div>
    </v-row>
  </div>
</template>
<script>
import store from "../store/index";
import CustomerListBar from "../components/CustomerListBar.vue";
import CustomerListAddEditModal from "../components/CustomerListAddEditModal.vue";
import ExpandableTable from "@/components/ExpandableTable.vue";
import {
  getUser,
  listItemTypes,
  listResultTypes,
  listUsers,
} from "../clients/tracelight";

export default {
  name: "CustomerList",
  components: {
    CustomerListBar,
    CustomerListAddEditModal,
    ExpandableTable,
  },
  data: () => ({
    initialLoad: true,
    currentUser: null,
    search: null,
    env: process.env.VUE_APP_ENV,
    customerStatus: null,
    customerTrial: null,
    isAdd: true,
    customer_name: "",
    customer_id: null,
    customerByUser: null,
    valueForGroup: false,
    timeout: false,
    mfaConfirmed: false,
    mfaPaired: false,
    tlUser: false,
    renderComponent: true,
    showClipboard: false,
    showMsg: null,
    items: [],
    headers: [
      {
        text: "Name",
        value: "name",
        filterable: true,
      },

      {
        text: "Primary Analyst",
        value: "primary_analyst_full_name",
        filterable: false,
      },

      { text: "Status", value: "status", filterable: false },
      { text: " ", value: "manage", filterable: false },
    ],
  }),
  mounted() {
    const payload = {};
    const otherParams = {
      page: 1,
      results_per_page: 3000,
    };
    const usersParams = {
      page: 1,
      results_per_page: 3000,
      user_type: "internal",
    };
    listItemTypes(payload, otherParams).then(function (result) {
      store.commit("customerList/fillItemTypes", result.data.data);
    });
    listResultTypes(payload, otherParams).then(function (result) {
      store.commit("customerList/fillResultTypes", result.data.data);
    });
    listUsers(payload, usersParams)
      .then(function (result) {
        store.commit("customerList/fillUsers", result.data.data);
      })
      .catch(function (error) {
        console.debug(error);
      });
    let queryString = window.location.search;
    if (queryString.length == 0) {
      this.initialUrlParams();
    }
    let urlParams = new URLSearchParams(queryString);
    this.updateUrlParams(urlParams);
    this.initialLoad = false;
  },
  methods: {
    initialUrlParams() {
      const payload = {};
      const params = {};
      this.customerStatus = true;
      this.updateCustomerStatus(this.customerStatus);
      getUser(payload, params).then((result) => {
        console.log(result);
        this.currentUser = result.data.data;
        this.customerByUser = this.currentUser.id;
        this.updateCustomersByUser(this.currentUser.id);
      });
    },
    updateUrlParams(urlParams) {
      for (const [key, value] of urlParams.entries()) {
        const val = urlParams.get(key);
        if (key == "enabled") {
          this.customerStatus = JSON.parse(val);
          this.updateCustomerStatus(JSON.parse(val));
        }
        if (key == "is_trial") {
          this.customerTrial = JSON.parse(val);
          this.updateCustomerTrial(JSON.parse(val));
        }
        if (
          key == "user_id" &&
          val !== null &&
          key == "user_id" &&
          val !== undefined
        ) {
          this.customerByUser = val;
          this.updateCustomersByUser(val);
        } else if (
          (key == "user_id" && val == "null") ||
          (key == "user_id" && val == null)
        ) {
          this.customerByUser = JSON.parse(null);
          this.updateCustomersByUser(JSON.parse(null));
        }
      }
    },
    updateCustomersByUser(userId) {
      console.log(userId);
      store.commit("customerList/replaceValue", {
        key: "user_id",
        value: userId,
      });

      store.dispatch("customerList/getCustomers");
    },
    updateCustomerTrial(is_trial) {
      store.commit("customerList/replaceValue", {
        key: "is_trial",
        value: is_trial,
      });
      if (this.initialLoad == false) {
        store.dispatch("customerList/getCustomers");
      }
    },
    updateCustomerStatus(enabled) {
      store.commit("customerList/replaceValue", {
        key: "customer_enabled",
        value: enabled,
      });
      if (this.initialLoad == false) {
        store.dispatch("customerList/getCustomers");
      }
    },
    customerProfile(item) {
      window.location.href = window.location.origin + "/customer/" + item.id;
    },
    customerItems(item) {
      console.warn(item);
      window.location.href =
        window.location.origin + "/customer/" + item.id + "/items";
    },
    customerReports(item) {
      setTimeout(() => {
        window.location.href =
          window.location.origin + "/customer/" + item.id + "/reports";
      }, 100);
    },

    customerAction: (item) => {
      return [
        {
          label: "Disable Trial",
          disabled: !item.is_trial,
          fn: () => {
            const payload = {
              customer_id: item.id,
              updateType: "isTrial",
              value: false,
            };
            store.dispatch("customerList/updateCustomers", payload);
          },
        },
        {
          label: "Enable Trial",
          disabled: item.is_trial,
          fn: () => {
            const payload = {
              customer_id: item.id,
              updateType: "isTrial",
              value: true,
            };
            store.dispatch("customerList/updateCustomers", payload);
          },
        },
        {
          label: "Delete",
          fn: () => {
            const payload = { customer_id: item.id };
            store.dispatch("customerList/deleteCustomers", payload);
          },
        },
        {
          label: "Disable",
          disabled: !item.enabled,
          fn: () => {
            const payload = {
              customer_id: item.id,
              updateType: "isEnabled",
              value: false,
            };
            store.dispatch("customerList/updateCustomers", payload);
          },
        },
        {
          label: "Enable",
          disabled: item.enabled,
          fn: () => {
            const payload = {
              customer_id: item.id,
              updateType: "isEnabled",
              value: true,
            };
            store.dispatch("customerList/updateCustomers", payload);
          },
        },
      ];
    },
    customerEdit: (item) => {
      const payload = {
        customer_id: item.id,
        updateType: "edit",
        name: document.getElementsByName("name")[0].value,
        description: document.getElementsByName("description")[0].value,
      };
      store.dispatch("customerList/updateCustomers", payload);
    },
  },
  computed: {
    customers() {
      const customers = store.state.customerList.customers;

      for (const customer of customers) {
        if (customer.primary_analyst !== null) {
          customer.primary_analyst_full_name =
            customer.primary_analyst.first_name +
            " " +
            customer.primary_analyst.last_name;
        }

        if (customer.enabled == true) {
          customer.status = "Enabled";
        }

        if (customer.enabled == false) {
          customer.status = "Disabled";
        }

        if (customer.enabled == true && customer.is_trial == true) {
          customer.status = "Trial";
        }

        if (customer.enabled == true && customer.is_trial == false) {
          customer.status = "Active";
        }
        customer.manage = "";
      }
      return customers;
    },
    progress() {
      return store.state.customerList.progress;
    },
    users() {
      const users = store.state.customerList.users || [];
      for (const user of users) {
        if (user.name) {
          user.full_name = user.name;
        } else {
          user.full_name = user.email;
        }
      }
      users.unshift({ full_name: "All", id: null });
      return users.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
    summary() {
      return store.state.customerList.summary;
    },
    readiness() {
      return store.state.customerList.readiness;
    },
    resultTypes() {
      return store.state.customerList.resultTypes;
    },
    itemTypes() {
      return store.state.customerList.itemTypes;
    },
    clipboardContent() {
      return store.state.customerList.clipboardContent;
    },
    hasSummary() {
      let array = [];
      if (this.customers) {
        array.push(this.customers.filter((customer) => customer.summary));
      }
      return array[0];
    },
    is_active_customer() {
      return store.state.customerList.customerStatsActive;
    },
    is_trial_customer() {
      return store.state.customerList.customerStatsTrial;
    },
    is_disabled_customer() {
      return store.state.customerList.customerStatsDisabled;
    },
    enabledCustomers() {
      let array = [];
      if (this.customers) {
        array.push(
          this.customers.filter((customer) => customer.enabled == true)
        );
      }
      return array[0];
    },
    disabledCustomers() {
      let array = [];
      if (this.customers) {
        array.push(
          this.customers.filter((customer) => customer.enabled == false)
        );
      }
      return array[0];
    },
  },
  watch: {
    clipboardContent: {
      handler: function (val) {
        if (val != null) {
          this.showMsg = val;
          this.showClipboard = true;
        }
        store.commit("customerList/clearClipboard");
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
#newCustomerList >>> td {
  white-space: nowrap;
  padding-left: 0px;
  padding-left: 10px;
}
#newCustomerList {
  margin-left: -10px;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

#newCustomerList >>> td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 200px;
  max-width: 200px;
}
#newCustomerList >>> td:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 150px;
  max-width: 150px;
}
#newCustomerList >>> td:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 250px;
  max-width: 250px;
}
#newCustomerList >>> th span {
  margin-left: -1em;
}

#newCustomerList >>> table tr:active {
  background: #58abe7 !important;
}
#newCustomerList >>> th {
  text-transform: uppercase;
  letter-spacing: 0.96px;
  color: #41669e !important;
}
#newCustomerList >>> td {
  letter-spacing: 0.32px;
  color: #ffffff;
}
#newCustomerList >>> .v-icon.v-icon.v-icon--link {
  color: #58abe7;
}
#newCustomerList >>> .v-data-table__expand-icon--active {
  color: #ffffff !important;
}
#newCustomerList >>> tr:hover .v-icon.v-icon.v-icon--link {
  color: #ffffff;
}
#newCustomerList >>> .text-start .rowIcon.v-icon {
  color: transparent !important;
  cursor: pointer;
}
#newCustomerList >>> tr:hover .text-start .rowIcon.v-icon {
  color: #112748 !important;
}
#newCustomerList >>> tr:hover .text-start .rowIconTrial.v-icon {
  color: #112748 !important;
}
#newCustomerList >>> th,
#newCustomerList >>> td {
  white-space: nowrap;
}

#newCustomerList >>> tr td:first-child .v-icon:focus::after {
  opacity: 0;
}
#newCustomerList >>> .v-data-table__expand-icon--active {
  transform: rotate(90deg);
}

#newCustomerList >>> .v-input {
  max-width: 200px;
}
</style>
