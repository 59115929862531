<template>
  <div>
    <v-dialog
      :overlay-color="'#000000'"
      :overlay-opacity="0.9"
      v-model="isOpen"
      max-width="350"
    >
      <v-card width="350" class="pl-8 pr-8 pt-3 pb-3 customerListAddEditModal">
        <v-card-title>
          <h5 class="white--text font-weight-light ml-n6 mb-2">
            {{ isAdd ? "Add Customer" : "Edit Account - " + customer_name }}
          </h5>
        </v-card-title>
        <v-row style="margin-top: -1em">
          <v-col style="max-width: 184px">
            <v-text-field
              v-model="name"
              label="Name"
              id="nameField"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-n4">
          <v-col>
            <v-textarea
              name="description"
              background-color="#153059"
              v-model="description"
              label="Description"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-card-actions>
          <div v-if="!isAdd">
            <v-row
              style="max-width: 184px; margin-left: -1em; margin-bottom: 0.5em"
            >
              <v-select
                :items="['Active', 'Trial', 'Disabled']"
                v-model="selectedChoice"
                label="Service Level"
              ></v-select>
            </v-row>
            <div class="ma-auto text-center" style="min-width: 270px">
              <v-btn
                class="ma-2"
                depressed
                color="accent"
                width="120"
                height="34"
                @click="editCustomer()"
                >Submit</v-btn
              >
            </div>
          </div>
          <div v-if="isAdd" class="ma-auto text-center">
            <v-btn
              class="ma-2 mt-4"
              color="accent"
              depressed
              width="120"
              height="34"
              @click="createCustomer()"
              >Submit</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
//import EventBus from "../../src/clients/event-bus";
import store from "../store/index";
export default {
  name: "customerlist-add-edit-modal",
  data: () => ({
    name: "",
    description: "",
    isEnabled: false,
    selectedChoice: null,
    isTrial: false,
  }),
  created() {
    /* EventBus.$on("customerlist_add_edit_modal", (payload) => {
      if (payload.type == "edit") {
        this.isAdd = false;
      } else {
        this.isAdd = true;
      }
      this.customer_name = payload.name;
      this.customer_id = payload.id;
      this.isOpen = true;
    }); */
  },
  watch: {
    customer_id: {
      handler: function (val) {
        if (val != null) {
          const currentCustomer = this.customers.filter(
            (customer) => customer.external_id == this.customer_id
          )[0];

          this.description = currentCustomer.description;
          this.name = currentCustomer.name;
          this.isEnabled = currentCustomer.enabled;
          this.isTrial = currentCustomer.is_trial;

          if (this.isEnabled == false) {
            this.selectedChoice = "Disabled";
          }

          if (this.isEnabled == true && this.isTrial == false) {
            this.selectedChoice = "Active";
          }

          if (this.isEnabled == true && this.isTrial == true) {
            this.selectedChoice = "Trial";
          }
        }
      },
      deep: true,
      immediate: true,
    },
    isOpen: {
      handler: function (val) {
        if (val == false) {
          this.name = "";
          this.description = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  props: {
    customer_id: {
      type: String,
      default: "",
    },
    customer_name: {
      type: String,
      default: "",
    },
  },
  computed: {
    customers() {
      return store.state.customerList.customers;
    },
    isAdd() {
      return store.state.customerList.isAdd;
    },
    isOpen: {
      get() {
        return store.state.customerList.isOpen;
      },
      set(newVal) {
        return this.$store.commit("customerList/replaceValue", {
          key: "isOpen",
          value: newVal,
        });
      },
    },
  },
  methods: {
    editCustomer() {
      const payload = {
        customer_id: this.customer_id,
        name: this.name,
        description: this.description,
        service: this.selectedChoice,
      };

      Object.keys(payload).forEach((key) => {
        if (payload[key] == null || payload[key] == "") {
          delete payload[key];
        }
      });

      store.dispatch("customerList/updateCustomers", payload);
      store.commit("customerList/replaceValue", {
        key: "isOpen",
        value: false,
      });
    },
    createCustomer() {
      const payload = { name: this.name, description: this.description };
      store.dispatch("customerList/createCustomers", payload);
      store.commit("customerList/replaceValue", {
        key: "isOpen",
        value: false,
      });
    },
  },
};
</script>
<style scoped>
.customerListAddEditModal >>> textarea {
  height: 70px;
  resize: none;
}

.customerListAddEditModal >>> .v-label {
  color: hsla(0, 0%, 100%, 0.7) !important;
}

.customerListAddEditModal >>> .v-text-field .v-label {
  color: hsla(0, 0%, 100%, 0.7) !important;
}

.customerListAddEditModal >>> .v-input,
.customerListAddEditModal >>> .v-input input,
.customerListAddEditModal >>> .v-input textarea {
  color: #fff;
  caret-color: #fff;
}
</style>
