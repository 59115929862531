<template>
  <td :colspan="colspan">
    <v-row>
      <v-col cols="12">
        <p class="body-1" style="text-align: center; padding: 15px 0">
          Users of this table can pass in any component to show for the expanded
          content.
        </p>
      </v-col>
    </v-row>
  </td>
</template>

<script>
export default {
  name: "expandable-default",
  props: {
    colspan: {
      type: Number,
      default: 7,
    },
  },
};
</script>
