<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
    :overlay-color="'#000000'"
    :overlay-opacity="0.9"
  >
    <v-card width="600">
      <v-card-title class="pl-12">
        <h4 class="accent--text font-weight-light ml-n2">
          {{ title }}
        </h4>
      </v-card-title>
      <v-form class="px-10" @submit="formHandler">
        <v-row v-for="(field, i) in fields" dense>
          <v-col cols="8">
            <v-text-field
              v-if="field.widget == 'text'"
              :label="field.label"
              :name="field.name"
              :value="field.value"
              dense
            />
            <v-text-field
              v-if="field.widget == 'readOnly'"
              :label="field.label"
              :name="field.name"
              :value="field.value"
              :readonly="true"
              :disabled="true"
              dense
            />
            <v-textarea
              background-color="#153059"
              v-if="field.widget == 'textarea'"
              :label="field.label"
              :name="field.name"
              :value="field.value"
            />
            <span v-if="field.widget == 'date'" class="fieldLabel">{{
              field.label
            }}</span>
            <date-picker
              v-if="field.widget == 'date'"
              :initialStartDate="field.value"
              :range="false"
              :submitHandler="saveDate(field.name)"
            ></date-picker>
            <p style="margin-bottom: 6px">&nbsp;</p>
          </v-col>
        </v-row>
        <v-btn color="accent" depressed width="120" height="34" type="submit">
          Submit
        </v-btn>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";

export default {
  name: "dialog-edit",
  components: {
    DatePicker,
  },
  data: () => ({
    field1: "fieldName",
    isOpen: false,
    dates: {},
  }),
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    requestOpen: {
      type: Boolean,
      default: false,
    },
    submitHandler: {
      type: Function,
      default: (e) => console.log(e),
    },
    title: {
      type: String,
      default: "A Reusable Edit Dialog",
    },
    headers: {
      type: Array,
      default: () => {
        return [
          { text: "Field One", value: "fieldOne", widget: "text" },
          { text: "Field Two", value: "fieldTwo", widget: "text" },
          { text: "Field Three", value: "fieldThree", widget: "textarea" },
        ];
      },
    },
  },
  computed: {
    fields: function () {
      let newFields = [];
      for (const header of this.headers) {
        if (header.edit) {
          const fieldValue = this.item[header.value];

          if (!header.widget) {
            header.widget = "text";
          }

          if (!header.value) {
            header.value = "";
          }

          newFields.push({
            name: header.value,
            widget: header.widget,
            label: header.text,
            value: fieldValue,
          });
        }
      }

      return newFields;
    },
  },
  methods: {
    formHandler: function (e) {
      e.preventDefault();
      const form = e.target;
      for (const header of this.headers) {
        if (header.edit) {
          if (header.widget == "date") {
            if (this.dates[header.value]) {
              this.item[header.value] = this.dates[header.value][0];
            }
          } else {
            this.item[header.value] = form.elements[header.value].value;
          }
        }
      }
      this.submitHandler(this.item);
      this.isOpen = false;
    },
    saveDate: function (name) {
      let dates = this.dates;
      return function (dateValue) {
        dates[name] = dateValue;
      };
    },
  },
  watch: {
    requestOpen(val) {
      if (val == true) {
        this.isOpen = true;
      }
    },
  },
};
</script>
