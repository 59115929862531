<script>
import { VDataTable, VIcon } from "vuetify/lib";
import Row from "vuetify/lib/components/VDataTable/Row";
import {
  getObjectValueByPath,
  getPrefixedScopedSlots,
  getPropertyFromItem,
} from "vuetify/lib/util/helpers";
import { mergeClasses } from "vuetify/lib/util/mergeData";

export default {
  extends: VDataTable,
  name: "data-table",
  methods: {
    genDefaultSimpleRow(item, classes, boolean) {
      const scopedSlots = getPrefixedScopedSlots("item.", this.$scopedSlots);

      const data = this.createItemProps(item);

      if (this.showSelect) {
        const slot = scopedSlots["data-table-select"];
        scopedSlots["data-table-select"] = slot
          ? () => slot(data)
          : () =>
              this.$createElement(VSimpleCheckbox, {
                staticClass: "v-data-table__checkbox",
                props: {
                  value: data.isSelected,
                  disabled: !this.isSelectable(item),
                },
                on: {
                  input: (val) => data.select(val),
                },
              });
      }

      if (this.showExpand) {
        const slot = scopedSlots["data-table-expand"];
        scopedSlots["data-table-expand"] = slot
          ? () => slot(data)
          : () =>
              this.$createElement(
                VIcon,
                {
                  staticClass: "v-data-table__expand-icon",
                  class: {
                    "v-data-table__expand-icon--active": data.isExpanded,
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                      data.expand(!data.isExpanded);
                    },
                  },
                },
                [this.expandIcon]
              );
      }

      const rowElement = this.$createElement(Row, {
        key: getObjectValueByPath(item, this.itemKey),
        class: mergeClasses(
          { ...classes, "v-data-table__selected": data.isSelected },
          getPropertyFromItem(item, this.itemClass)
        ),
        props: {
          headers: this.computedHeaders,
          hideDefaultHeader: this.hideDefaultHeader,
          item,
          rtl: this.$vuetify.rtl,
        },
        scopedSlots,
        on: {
          click: (e) => {
            if (this.showExpand) {
              if (e.target.nodeName !== "BUTTON") {
                e.stopPropagation();
                data.expand(!data.isExpanded);
              }
            }
          },
          //          click: () => this.$emit('click:row', item, data),
          contextmenu: (event) => this.$emit("contextmenu:row", event, data),
          dblclick: (event) => this.$emit("dblclick:row", event, data),
        },
      });

      return rowElement;
    },
  },
};
</script>
