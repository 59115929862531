<template>
  <div class="customerlist-bar">
    <v-alert dark prominent height="80px">
      <v-row class="d-flex no-gutters mt-n1">
        <v-col cols="6">
          <h2 class="mt-2 font-weight-light">All Clients</h2>
        </v-col>
        <v-col cols="1"> </v-col>
        <v-col>
          <span class="barData"
            >{{ clients
            }}<span class="barDataBottom caption text-uppercase"
              >Active</span
            ></span
          >
        </v-col>
        <v-col>
          <span class="barData">
            {{ trials }}

            <span class="barDataBottom caption text-uppercase">Trial</span>
          </span>
        </v-col>
        <v-col>
          <span class="barData"
            >{{ isDisabled
            }}<span class="barDataBottom caption text-uppercase"
              >Disabled</span
            ></span
          >
        </v-col>
        <v-col>
          <v-icon color="error" class="mt-4 ml-10" @click="addCustomer()"
            >mdi-plus-circle</v-icon
          >
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script>
//import EventBus from "../../src/clients/event-bus";
import store from "../store/index";
export default {
  name: "customer-list-bar",
  data: () => ({
    env: process.env.VUE_APP_ENV,
  }),
  props: {
    clients: {
      type: Number,
      default: 0,
    },
    trials: {
      type: Number,
      default: 0,
    },
    isDisabled: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    addCustomer() {
      let routeData = this.$router.resolve({
        path: "/customer/create_client",
      });
      window.location.href = routeData.href;
    },
  },
};
</script>
<style scoped>
.customerlist-bar {
  width: 100%;
  margin-top: 1.5em;
}
.barData {
  font-size: 1.5em;
  font-weight: 100;
}
.barDataBottom {
  display: flex;
  align-self: center;
  color: #ffffff;
  opacity: 0.2;
}
</style>
