<template>
  <div class="expandable-table">
    <v-layout class="ml-6" v-if="searchEnabled">
      <v-flex md2 d-flex class="mr-10">
        <v-text-field
          v-model="search"
          label="Filter Results"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
      <v-flex md2 d-flex>
        <slot name="additionalFilter"></slot>
      </v-flex>
    </v-layout>
    <h2 class="mt-2 mb-2 ml-3">{{ title }}</h2>
    <data-table
      :expanded.sync="expanded"
      :headers="allHeaders"
      :items="itemData"
      item-key="id"
      :items-per-page="-1"
      expand-icon=""
      disable-sort
      hide-default-footer
      :search="search"
      show-expand
      single-expand
    >
      <template v-slot:item.id="{ item }">
        <span style="visibility: hidden">{{ item.id }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row style="margin-left: -3em">
          <slot name="additionalRowIcon" v-bind:item="item"></slot>
          <v-col cols="1" v-if="showActionEdit == true">
            <v-icon class="rowIcon" @click="openEditDialog(item)">
              mdi-pencil
            </v-icon>
          </v-col>
          <v-col cols="1" v-if="showActionEdit == true">
            <v-icon class="rowIcon" @click="openActionDialog(item)"
              >mdi-cogs</v-icon
            >
          </v-col>
        </v-row>
      </template>

      <!--      <template v-slot:expanded-item="{ item }" v-if="disableExpand == false">
        <component
          :is="expandedComponent"
          :headers="headers"
          :item="item"
          v-if="renderComponent"
        />
      </template> -->
    </data-table>

    <dialog-edit
      :requestOpen="requestDialogEdit"
      :item="activeItem"
      :headers="headers"
      :submitHandler="editSubmit"
      :title="`Edit Item - ${activeItem.name}`"
    />
    <dialog-action
      :requestOpen="requestDialogAction"
      :actions="actions(activeItem)"
      :title="`Actions For Item - ${activeItem.name}`"
    />
  </div>
</template>

<script>
import defaultData from "./default_data/MyTable.js";
import DataTable from "@/components/DataTable.vue";
import DialogAction from "@/components/DialogAction.vue";
import DialogEdit from "@/components/DialogEdit.vue";
import ExpandableDefault from "@/components/ExpandableDefault.vue";

export default {
  name: "expandble-table",
  components: { DataTable, DialogAction, DialogEdit, ExpandableDefault },
  data: () => ({
    activeItem: {},
    requestDialogAction: false,
    requestDialogEdit: false,
    renderComponent: true,
    expanded: [],
    search: "",
  }),
  mounted() {
    const ths = document.querySelectorAll("th");
    for (const th of ths) {
      if (th.textContent == "") {
        th.classList.add("tight-table-column");
      }
    }
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    showActionEdit: {
      type: Boolean,
      default: true,
    },
    searchEnabled: {
      type: Boolean,
      default: false,
    },
    disableAction: {
      type: Boolean,
      default: false,
    },
    disableExpand: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: function () {
        return defaultData.headers;
      },
    },
    items: {
      type: Array,
      default: function () {
        return defaultData.items;
      },
    },
    expandedComponent: {
      type: Object,
      default: () => ExpandableDefault,
    },
    editSubmit: {
      type: Function,
      default: (updatedItem) => {
        //console.log("Edit", updatedItem);
      },
    },
    htmlCell: {
      type: Function,
      default: (item) => {
        return [
          {
            fn: () => {
              `<span v-html="${item.htmlCell}"></span>`;
            },
          },
        ];
      },
    },
    htmlCellOne: {
      type: Function,
      default: (item) => {
        return [
          {
            fn: () => {
              `<span v-html="${item.htmlCellOne}"></span>`;
            },
          },
        ];
      },
    },
    htmlCellTwo: {
      type: Function,
      default: (item) => {
        return [
          {
            fn: () => {
              `<span v-html="${item.htmlCellTwo}"></span>`;
            },
          },
        ];
      },
    },
    htmlCellThree: {
      type: Function,
      default: (item) => {
        return [
          {
            fn: () => {
              `<span v-html="${item.htmlCellThree}"></span>`;
            },
          },
        ];
      },
    },
    actions: {
      type: Function,
      default: (item) => {
        return [
          {
            label: "No actions available for this item.",
            fn: () => {
              console.log(`Delete ${item.name}`);
            },
          },
        ];
      },
    },
  },
  computed: {
    allHeaders() {
      let headers = this.headers;
      headers = headers.filter((obj) => obj.hide != true);
      headers = headers.filter((obj) => obj.value != "actions");
      headers.push({ text: "", value: "actions" });
      return headers;
    },
    itemData() {
      if (this.items.length == 0) {
        return;
      }
      //console.log(this.items.filter((r) => r.name == "test-march-29")[0]);
      const maxChars = 5000;
      let rows = this.items;
      let createIds = false;
      if (!Object.keys(rows[0]).includes("id")) {
        createIds = true;
      }
      for (const [index, row] of rows.entries()) {
        if (createIds) {
          row["id"] = `table-id-${index}`;
        }

        for (const key of Object.keys(row)) {
          if (typeof row[key] == "string") {
            if (row[key].length > maxChars) {
              row[key] = row[key].slice(0, maxChars - 3) + "...";
            }
          }
        }
      }

      const anItem = rows.filter((r) => r.name == "test-march-29")[0];
      return rows;
    },
  },
  watch: {
    expanded: function (val) {
      /*     if (val.length > 0) {
        store
          .dispatch("customerList/getCustomerSummary", val[0].id)
          .then((result) => this.forceRerender());
        store
          .dispatch("customerList/getCustomerProgress", val[0].id)
          .then((result) => this.forceRerender());
        store
          .dispatch("customerList/getCustomerProgressByType", val[0].id)
          .then((result) => this.forceRerender());
      } */
    },
  },
  methods: {
    getData(item) {
      //console.debug(item);
    },
    handleSubmit(updatedItem) {
      //console.log(updatedItem);
    },
    openActionDialog(item) {
      this.activeItem = item;
      this.requestDialogAction = true;
      window.setTimeout(() => (this.requestDialogAction = false), 100);
    },
    openEditDialog(item) {
      this.activeItem = item;
      this.requestDialogEdit = true;
      window.setTimeout(() => (this.requestDialogEdit = false), 100);
    },
    /*   forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    }, */
  },
};
</script>
<style scoped>
.expandable-table >>> table {
  width: 100%;
}
.expandable-table >>> table tr:active {
  background: #58abe7 !important;
}
.expandable-table >>> th {
  text-transform: uppercase;
  letter-spacing: 0.96px;
  color: #41669e !important;
}
.expandable-table >>> td {
  letter-spacing: 0.32px;
  color: #ffffff;
}
.expandable-table >>> .v-icon.v-icon.v-icon--link {
  color: #58abe7;
}
.expandable-table >>> .v-data-table__expand-icon--active {
  color: #ffffff !important;
}
.expandable-table >>> tr:hover .v-icon.v-icon.v-icon--link {
  color: #ffffff;
}
.expandable-table >>> .text-start .rowIcon.v-icon {
  color: transparent !important;
  cursor: pointer;
}
.expandable-table >>> tr:hover .text-start .rowIcon.v-icon {
  color: #112748 !important;
}
.expandable-table >>> th,
.expandable-table >>> td {
  white-space: nowrap;
}

.expandable-table >>> tr td:last-child {
  min-width: 150px;
}

.expandable-table >>> tr td:first-child .v-icon:focus::after {
  opacity: 0;
}
.expandable-table >>> .v-data-table__expand-icon--active {
  transform: rotate(90deg);
}
</style>
